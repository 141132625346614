import { Avatar, Button, CircularProgress } from '@mui/material'
import ErrorFallback from 'components/ErrorFallback'
import { ModalState } from 'components/modal'
import { useHospitalStaff, useProviders } from 'data/hospitals'
import { useAllTreatments, useMedication, usePrescriptionDrugs } from 'data/treatments'
import { useParams } from 'react-router-dom'
import { formatCurrency } from 'utils'
import SelectShippingModal from './SelectShippingModal'
import { isArray } from 'lodash'

export default function MedicationDetails() {
  const { medicationId, id } = useParams()
  const { data, isLoading, isError, refetch } = useMedication(medicationId as string)

  const { data: prescriptionDrugs } = usePrescriptionDrugs(data?.treatment_session_id as string)
  const { data: hospitalStaff } = useHospitalStaff(id as string)
  const prescriptionDetails = prescriptionDrugs?.find(
    (item) => item.id === data?.prescription_drug_id
  )
  const prescribedBy = hospitalStaff?.find((item) => item.id === data?.prescribed_by)

  //using this pattern for now , due to time constraints
  const { data: treatments } = useAllTreatments()
  const treatment = treatments?.find(
    (item) => item.treatment_session_id === data?.treatment_session_id
  )

  const { data: providers } = useProviders()

  return isLoading || !isArray(prescriptionDrugs) ? (
    <div className="h-96 w-full grid place-items-center">
      <CircularProgress />
    </div>
  ) : isError ? (
    <ErrorFallback
      message="Something went wrong"
      description="Sorry , we could not fetch medication"
      reset={refetch}
    />
  ) : (
    prescriptionDetails && (
      <div className="space-y-8">
        <div className="rounded-xl p-5 border border-default font-medium space-y-4 w-full mb-3">
          <p className="font-medium ">Drug name - {prescriptionDetails?.drug_name}</p>
          <p className="font-medium ">Dose - {data.dose}</p>
          <p className="font-medium ">Dosage form - {data.dosage_form}</p>
          <div className="flex">
            Prescribed By -{' '}
            <div className="flex items-center space-x-2 ml-2">
              <Avatar className="w-6 h-6" src={prescribedBy?.profile_picture} />{' '}
              <p>{`${prescribedBy?.first_name} ${prescribedBy?.last_name}`}</p>
            </div>{' '}
          </div>
          <div className="flex">
            Processed By -{' '}
            <div className="flex items-center space-x-2 ml-2">
              <p>{providers?.find((p) => p.id === treatment?.hospital_id)?.name}</p>
            </div>{' '}
          </div>
          <div className="justify-between flex font-medium ">
            <p>Location - {data.pharmacy_address}</p>
          </div>

          <div className="justify-between text-sm flex font-medium">
            <p>Price - {formatCurrency(data.price)}</p>
          </div>
          <p className="font-medium">Approved Quantity - {data.approved_quantity}</p>
        </div>

        <h1 className="text-center text-brand-900 font-bold text-lg">Dosage</h1>

        <div className="rounded-xl p-5 border border-default font-medium space-y-4 w-full mb-3">
          <div className="flex justify-between">
            <p className="font-medium">Unit - {data.dosage.unit || 'Not available'}</p>
          </div>

          <div className="flex justify-between">
            <p className="font-medium">
              Type - {data.otc ? 'Over The Counter (OTC)' : 'Prescription'}
            </p>
          </div>

          <div className="flex justify-between">
            <p className="font-medium">Interval - {data.dosage.interval || 'Not available'}</p>
          </div>

          <div className="flex justify-between">
            <p className="font-medium">
              No of Times - {data.dosage.interval_count || 'Not available'}
            </p>
          </div>

          <div className="flex justify-between">
            <p className="font-medium">Note - {data.dosage.notes}</p>
          </div>

          <div className="flex justify-between items-center">
            <p className="font-medium">Ship - {data.dosage.ship ? 'True' : 'False'}</p>

            {!data.otc &&
              treatment &&
              !['completed', 'cancelled'].includes(treatment?.treatment_status) && (
                <ModalState>
                  {(isVisible, open, close) => (
                    <>
                      <SelectShippingModal isVisible={isVisible} close={close} medication={data} />
                      <Button className="lg:block hidden px-10" onClick={open}>
                        Request Refill
                      </Button>
                    </>
                  )}
                </ModalState>
              )}
          </div>
        </div>

        {!data.otc &&
          treatment &&
          !['completed', 'cancelled'].includes(treatment?.treatment_status) && (
            <ModalState>
              {(isVisible, open, close) => (
                <>
                  <SelectShippingModal isVisible={isVisible} close={close} medication={data} />
                  <Button fullWidth className="lg:hidden" onClick={open}>
                    Request Refill
                  </Button>
                </>
              )}
            </ModalState>
          )}
      </div>
    )
  )
}
