import { Button, Chip, IconButton } from '@mui/material'
import { ReactComponent as Printer } from 'assets/icons/printer.svg'
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined'
import { useParams } from 'react-router-dom'
import { formatCurrency, formatDate } from 'utils'
import { CircularProgress } from '@mui/material'
import ErrorFallback from 'components/ErrorFallback'
import { format } from 'date-fns'
import { useRef } from 'react'
import html2pdf from 'html2pdf.js'
import { useOrderRequest } from 'data/orders'
import TopNav from 'components/TopNav'
import { ModalState } from 'components/modal'
import { useLoggedInProfile } from 'data/auth'
import MakePaymentModal from './MakePayment'
import { UserProfile } from 'types/auth'
import { Capacitor } from '@capacitor/core'

const chipColor = {
  paid: 'success',
  outstanding: 'error',
} as const
interface Item {
  product_name: string
  dosage_form: string
  quantity: number
  brand: string
  product_type: 'drug' | 'others' | 'medical-supplies'
  cost: number
}

export default function PaymentRequestDetails() {
  const { id } = useParams()
  const { data, isLoading, isError, refetch } = useOrderRequest(id as string)
  const contentRef = useRef<null | HTMLDivElement>(null)
  const { data: profile } = useLoggedInProfile()

  const downloadPDF = async () => {
    const element = contentRef.current
    if (element) {
      ;(document.getElementById('status') as HTMLElement).style.paddingBottom = '15px'
      html2pdf(element, {
        // pagebreak: { mode: ['css', 'legacy'] },
        html2canvas: {
          scale: 3,
          ignoreElements: function (el: HTMLElement) {
            return el.classList.contains('noPdf')
          },
        },
        // jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape' },
        filename: `${data?.user?.first_name} ${data?.user?.last_name} order receipt`,
      }).then(() => {
        //revert to normal on the UI
        ;(document.getElementById('status') as HTMLElement).style.paddingBottom = ''
      })
    }
  }
  function RequestItems(data: Item[], itemType: string) {
    return (
      <div>
        {data?.map((item, index) => (
          <div className="p-5 border-b-2 border-default space-y-6" key={index}>
            <p className="capitalize">Item - {itemType}</p>
            <div className="flex space-x-2">
              <p>Description - </p>
              <div className="space-y-2">
                <p>
                  {itemType === 'drug' ? 'Drug' : 'Product'} - {item.product_name}
                </p>
                <p>Brand - {item.brand}</p>
                <p>Dosage Form - {item.dosage_form}</p>
                <p>Quantity - {item.quantity} Pack</p>
              </div>
            </div>
            <p>Amount - {formatCurrency(item.cost)}</p>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="grid grid-cols-1">
      <TopNav title="Order History" subtitle="Payment Request" />
      <>
        {' '}
        <div className="lg:hidden">
          <p className="text-center py-6">
            Order summary - {`${data?.user.first_name || ''} ${data?.user.last_name || ''}`}
          </p>
          {isLoading ? (
            <>
              {' '}
              <div className="grid place-items-center h-96 w-full">
                <CircularProgress />
              </div>
            </>
          ) : isError ? (
            <ErrorFallback
              message="Something went wrong"
              description="We could not fetch invoice"
              reset={refetch}
              buttonText="Try again"
            />
          ) : (
            <div>
              <div className="flex justify-between items-center">
                <div className="space-y-4">
                  <p>Status -</p>
                  <Chip
                    label={data.invoice?.invoice_status}
                    color={chipColor[data.invoice?.invoice_status as keyof typeof chipColor]}
                  />
                </div>
                <div className="flex flex-col space-y-4">
                  <p>Invoice No - {data.invoice?.invoice_number}</p>
                  {data.request_status === 'pending' && (
                    <ModalState>
                      {(isVisible, open, close) => (
                        <>
                          <MakePaymentModal
                            isVisible={isVisible}
                            close={() => {
                              close()
                            }}
                            profile={profile as UserProfile}
                            amount={
                              data.shipping
                                ? data.invoice.amount + data.shipping.total_amount
                                : data.invoice.amount
                            }
                            requestId={data.invoice.request_id}
                          />
                          <Button
                            className="px-6 w-full self-end font-semibold rounded-xl"
                            fullWidth
                            onClick={open}
                          >
                            Pay
                          </Button>
                        </>
                      )}
                    </ModalState>
                  )}
                </div>
              </div>
              <div className=" space-y-4 mt-6">
                <p>Date - {data?.invoice?.created_at && formatDate(data?.invoice?.created_at)}</p>
                <p>
                  Time -{' '}
                  {data?.invoice?.created_at &&
                    format(new Date(data?.invoice?.created_at as string), 'h:mm a')}
                </p>
              </div>
              <div className="rounded-xl mt-6 border-2 border-default font-medium space-y-6 pb-6">
                <>
                  <div>
                    {RequestItems(data.items.drug, 'drug')}
                    {RequestItems(data.items.others, 'product')}
                    {RequestItems(data.items['medical-supplies'], 'medical supplies')}
                  </div>
                  {data.shipping && (
                    <div className=" p-5 border-b-2 border-default space-y-6  ">
                      <p>Item - Shipping</p>
                      <div className="flex space-x-2">
                        <p>Description - {data.shipping?.item}</p>{' '}
                      </div>
                      <p>Amount - {formatCurrency(data.shipping?.total_amount || 0)}</p>
                    </div>
                  )}

                  <div className=" p-5 border-b-2 border-default space-y-6  ">
                    <p>Item - Processing Fee</p>
                    <div className="flex space-x-2">
                      <p>Description - Order Processing Fee</p>{' '}
                    </div>
                    <p>Amount - {formatCurrency(data.processing_fee || 0)}</p>
                  </div>
                </>

                <div className="p-5 space-y-6">
                  <p>
                    Subtotal -{' '}
                    {formatCurrency(
                      data.shipping
                        ? data.invoice?.amount + data.shipping?.total_amount
                        : data.invoice?.amount
                    )}
                  </p>
                  <p>Delivered To - {`${data.user.last_name} ${' '} ${data.user.first_name} `}</p>
                </div>
                <div className="flex space-x-2 px-5">
                  <p>Bill to -</p>{' '}
                  <p>
                    {`${data.user.last_name} ${' '} ${data.user.first_name} `} <br />
                    {`${data.user.email}, ${' '} ${data.user.phone} `}
                  </p>
                </div>
                <div className="px-5 space-y-6">
                  <Chip
                    label={`Sub Total - ${formatCurrency(
                      data.shipping
                        ? data.invoice?.amount + data.shipping?.total_amount
                        : data.invoice?.amount
                    )}`}
                    className="bg-brand-100 text-brand-900 "
                  />
                  <div>
                    <Chip
                      label={`Total - ${formatCurrency(
                        data.shipping
                          ? data.invoice?.amount + data.shipping?.total_amount
                          : data.invoice?.amount
                      )}`}
                      className="bg-brand-500 text-white"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-16">
                <div>
                  <p className="text-brand-400">Raised By -</p>
                  <p>
                    {`${data.user.last_name} ${' '} ${data.user.first_name} `} <br />
                    {` ${'('}${data.user.email},  ${' '}`} <br /> {`${data.user.phone}${')'}`}{' '}
                  </p>
                </div>
                {!Capacitor.isNativePlatform() && (
                  <div>
                    <p className="text-brand-400">Action -</p>
                    <div className="flex">
                      <IconButton
                        className="flex-col space-y-1 hover:bg-transparent"
                        onClick={downloadPDF}
                      >
                        <Printer />
                        <p className="text-sm">Print</p>
                      </IconButton>
                      <IconButton className="flex-col space-y-1 hover:bg-transparent">
                        <SendToMobileOutlinedIcon />
                        <p className="text-sm">Send</p>
                      </IconButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="hidden lg:block ">
          {isLoading ? (
            <>
              {' '}
              <div className="grid place-items-center h-96 w-full">
                <CircularProgress />
              </div>
            </>
          ) : isError ? (
            <ErrorFallback
              message="Something went wrong"
              description="We could not fetch invoice"
              reset={refetch}
              buttonText="Try again"
            />
          ) : (
            <>
              <div
                ref={contentRef}
                className="rounded-xl p-5  mt-6 border border-default font-medium space-y-6  w-[90%] mx-auto"
              >
                <p className="title-desktop text-brand-400">INVOICE</p>

                <div className="flex justify-between">
                  <div className=" space-y-2">
                    <p className="text-sm">{data?.user?.email}</p>
                    <p className="text-sm">{data?.user?.phone}</p>
                  </div>
                  <div className="space-y-2">
                    <div className="flex justify between">
                      <p className="w-full">Invoice #</p>
                      <p className="flex justify-end w-full">{data.invoice?.invoice_number}</p>
                    </div>
                    <div className="grid grid-cols-2">
                      <p className="w-full">Date</p>
                      <p className="flex justify-end w-full">{`${
                        data?.invoice?.created_at && formatDate(data.invoice?.created_at)
                      } ${' '} ${
                        data?.invoice?.created_at &&
                        format(new Date(data.invoice?.created_at as string), 'h:mm a')
                      } `}</p>
                    </div>
                    <div className="flex justify between">
                      <p className="w-full">Status</p>
                      <Chip
                        label={<p id="status">{data.invoice?.invoice_status}</p>}
                        color={chipColor[data.invoice?.invoice_status as keyof typeof chipColor]}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className=" space-y-2">
                    <p className="text-lg font-bold">Bill To</p>
                    <p className="text-sm">
                      {' '}
                      {`${data.user?.first_name} ${' '} ${data.user?.last_name} `}{' '}
                    </p>
                    <p className="text-sm"> {data.user?.phone} </p>
                  </div>
                  {data.request_status === 'pending' && (
                    <div className="space-y-2 w-1/2 flex justify-end noPdf">
                      <ModalState>
                        {(isVisible, open, close) => (
                          <>
                            <MakePaymentModal
                              isVisible={isVisible}
                              close={() => {
                                close()
                              }}
                              profile={profile as UserProfile}
                              amount={
                                data.shipping
                                  ? data.invoice.amount + data.shipping.total_amount
                                  : data.invoice.amount
                              }
                              requestId={data.invoice.request_id}
                            />
                            {data.invoice?.invoice_status !== 'paid' && (
                              <Button className="w-1/4" fullWidth onClick={open}>
                                Pay
                              </Button>
                            )}
                          </>
                        )}
                      </ModalState>
                    </div>
                  )}
                </div>
                <div>
                  <table className="w-full table-auto mb-0 mt-0 space-y-0">
                    <thead className="bg-brand-50 text-brand-900 p-0 h-10">
                      <tr>
                        <th className="text-start py-4 px-2">S/N</th>
                        <th className="text-start px-8">Item</th>
                        <th className="text-start px-8">Description</th>
                        <th className="text-start px-8">Amount </th>
                      </tr>
                    </thead>
                    <tbody className="border">
                      {data &&
                        (() => {
                          let serialNumber = 1

                          const renderRows = (items: Item[], itemType: string) => {
                            return items?.map((item, index) => (
                              <tr key={index}>
                                <td className="px-2 border">{serialNumber++}</td>
                                <td className={`px-8 border capitalize`}>{itemType}</td>
                                <td className="border p-8 space-y-2">
                                  <p>
                                    {itemType === 'drug' ? 'Drug' : 'Product'} - {item.product_name}
                                  </p>
                                  <p>Brand - {item.brand}</p>
                                  <p>Dosage Form - {item.dosage_form}</p>
                                  <p>Quantity - {item.quantity} Pack</p>
                                </td>
                                <td className="p-8 border">{formatCurrency(item.cost)}</td>
                              </tr>
                            ))
                          }

                          return (
                            <>
                              {renderRows(data.items.drug, 'drug')}
                              {renderRows(data.items.others, 'product')}
                              {renderRows(data.items['medical-supplies'], 'medical supplies')}
                              {data.shipping && (
                                <tr>
                                  <td className="px-2 border py-4">{serialNumber++}</td>
                                  <td className="border px-8">{data.shipping.item}</td>
                                  <td className="border px-8">{data.shipping.description}</td>
                                  <td className="border px-8">
                                    {formatCurrency(data.shipping.total_amount || 0)}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td className="px-2 border py-4">{serialNumber}</td>
                                <td className="border px-8">Processing Fee</td>
                                <td className="border px-8">Order Processing Fee</td>
                                <td className="border px-8">
                                  {' '}
                                  {formatCurrency(data.processing_fee || 0)}
                                </td>
                              </tr>
                            </>
                          )
                        })()}
                    </tbody>
                  </table>
                  <div className="flex items-end mt-0 w-full space-y-[1px] flex-col">
                    <div className="bg-brand-100 w-1/2 text-brand-900 h-14 items-center flex justify-between px-10">
                      <p>Sub Total - </p>
                      <p>
                        {formatCurrency(
                          data.shipping
                            ? data.invoice.amount + data.shipping.total_amount
                            : data.invoice.amount
                        )}
                      </p>
                    </div>
                    <div className="bg-brand-400  w-1/2 text-white h-14 items-center flex justify-between px-10">
                      <p>Total - </p>
                      <p>
                        {' '}
                        {formatCurrency(
                          data.shipping
                            ? data.invoice.amount + data.shipping.total_amount
                            : data.invoice.amount
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="border-t border-dashed my-16 w-9/12 border-default mx-auto" />
                  <div className="flex justify-between mt-16">
                    <div className="space-y-2  custom-table-class">
                      <p className="text-brand-400">Raised By -</p>
                      <p className="w-[15ch]">
                        {' '}
                        {`${data.user.last_name} ${' '} ${data.user.first_name} `}{' '}
                        {`${data.user.email}, ${' '} ${data.user.phone} `}
                      </p>
                    </div>
                    <div className="space-y-2">
                      <div className="flex">
                        {!Capacitor.isNativePlatform() && (
                          <div className="space-y-2 noPdf">
                            <p className="text-brand-400">Action -</p>
                            <div className="flex">
                              <IconButton
                                className="flex-col space-y-1 hover:bg-transparent"
                                onClick={downloadPDF}
                              >
                                <Printer />
                                <p className="text-sm">Print</p>
                              </IconButton>
                              <IconButton className="flex-col space-y-1 hover:bg-transparent">
                                <SendToMobileOutlinedIcon />
                                <p className="text-sm">Send</p>
                              </IconButton>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </div>
  )
}
