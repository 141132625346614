import { useQuery } from '@tanstack/react-query'
import makeApiCall from 'utils/api'
import { Hospital, Provider, Staff } from 'types/hospital'
import { QueryKey } from 'react-query'
;('utils/api/product')

export function useHospitals() {
  return useQuery<Hospital[]>(['hospitals/my-hospitals'])
}

export function useHospitalStaff(hospital_id: string, account_type?: string) {
  return useQuery<Staff[]>(['hospitals/staff', hospital_id, account_type], {
    queryFn: ({ queryKey }: { queryKey: QueryKey }) =>
      makeApiCall(`${queryKey[0]}`, '', '', {
        params: {
          nopaginate: true,
          hospital_id,

          account_type: account_type || undefined,
        },
      }),
    enabled: !!hospital_id,
  })
}

export function useProviders(kind = 'mine', type = 'hospital') {
  return useQuery<Provider[]>(['providers', kind, type], {
    queryFn: ({ queryKey }: { queryKey: QueryKey }) =>
      makeApiCall(`${queryKey[0]}`, '', '', {
        params: {
          nopaginate: true,
          kind,
          type,
        },
      }),
  })
}

export function useHospital(id: string) {
  return useQuery<Hospital>([`hospitals/${id}`], {
    queryFn: ({ queryKey }: { queryKey: QueryKey }) => makeApiCall(`${queryKey[0]}`),
  })
}
