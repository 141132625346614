import React from 'react'
import { IconButton, Button } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const TopNav = ({
  title,
  subtitle,
  className,
}: {
  title: string
  className?: string
  subtitle?: string
}) => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <header className={`h-10 relative text-primary mb-3 lg:hidden ${className}`}>
        <IconButton
          className="w-10 absolute left-0 top-0 text-center h-full"
          onClick={() => navigate(-1)}
        >
          <span className="sr-only">Go back to plans</span>
          <KeyboardArrowLeft className="text-neutral-900" />
        </IconButton>
        <h1 className="text-lg text-neutral-900 font-bold font-sans text-center w-full h-full py-2">
          {title}
        </h1>
      </header>
      <h1 className="font-bold text-neutral-900 mb-5 lg:block hidden text-lg">{title}</h1>
      <header className={`hidden lg:flex items-center mb-4  ${className}`}>
        <Button
          onClick={() => navigate(-1)}
          className="p-0 hover:bg-transparent"
          variant="text"
          startIcon={<KeyboardArrowLeft className="text-brand-400 " />}
        >
          <p className="text-center font-medium text-brand-400 text-sm">Go back</p>
        </Button>
      </header>
      <h1 className="font-medium text-center lg:text-left lg:block text-left">{subtitle}</h1>
    </React.Fragment>
  )
}

export default TopNav
