import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DepositPayload } from 'types/bill'
import { PayInvoicePayload, PaymentRequestPayload } from 'types/payment'
import { fundDeposit } from 'utils/api/bills'
import { fundWallet, payInvoice, paymentRequest } from 'utils/api/payments'

export function useFundWallet() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: { amount: number }) => fundWallet(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/wallets/get-wallet'] })
    },
  })
}

export function useDepositFunds() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: DepositPayload) => {
      return fundDeposit(payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/wallets/get-wallet'] })
    },
  })
}

export function usePayInovice() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: { id: string; payload: PayInvoicePayload }) => {
      return payInvoice(payload.id, payload.payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/wallets/get-wallet'] })
    },
  })
}
export function usePaymentRequest() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: { id: string; payload: PaymentRequestPayload }) => {
      return paymentRequest(payload.id, payload.payload)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/wallets/get-wallet'] })
    },
  })
}
