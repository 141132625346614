import { useQuery } from '@tanstack/react-query'
import makeApiCall from 'utils/api'
import { QueryKey } from 'react-query'
import { DrugPrescription, Medication, Refill, SingleMedication, Treatment } from 'types/treatments'
const baseURL = import.meta.env.VITE_API_GLOBAL_URL

export function useTreatments(
  offset: number,
  limit: number | string,
  hospital_id: string,
  institution_id: string,
  filters?: {
    from_date: string | undefined
    to_date: string | undefined
    doctor_id: string | undefined
    diagnosis: string | undefined
    treatment_status: Treatment['treatment_status'] | undefined
    date: string | undefined
  }
) {
  return useQuery<{ items: Treatment[]; item_count: number }>(
    ['/treatments', offset, limit, filters, hospital_id, institution_id],
    {
      queryFn: ({ queryKey }: { queryKey: QueryKey }) =>
        makeApiCall(`${queryKey[0]}`, '', '', {
          params: {
            offset,
            limit,
            hospital_id,
            institution_id,
            ...filters,
          },
        }),
    }
  )
}

export function useAllTreatments() {
  return useQuery<Treatment[]>(['/treatments'], {
    queryFn: ({ queryKey }: { queryKey: QueryKey }) =>
      makeApiCall(`${queryKey[0]}`, '', '', {
        params: {
          nopaginate: true,
        },
      }),
  })
}

export function usePrescriptions(payload: {
  treatment_session_id: string
  offset: number
  limit: number
}) {
  return useQuery<{ items: DrugPrescription[]; item_count: number; offset: number }>(
    [`/prescription-drugs`, payload.treatment_session_id, payload.offset, payload.limit],
    {
      queryFn: ({ queryKey }) =>
        makeApiCall(`${queryKey[0]}`, '', '', {
          params: {
            offset: payload.offset,
            limit: payload.limit,
            treatment_session_id: payload.treatment_session_id,
          },
        }),
    }
  )
}

export function useMedications(payload: {
  treatment_session_id: string
  offset: number
  limit: number
}) {
  return useQuery<{ items: Medication[]; item_count: number; offset: number }>(
    [`/medications`, payload.treatment_session_id, payload.offset, payload.limit],

    {
      queryFn: ({ queryKey }) =>
        makeApiCall(`${queryKey[0]}`, '', '', {
          params: {
            offset: payload.offset,
            limit: payload.limit,
            treatment_session_id: payload.treatment_session_id,
          },
        }),
    }
  )
}

export function usePrescriptionDrugs(treatment_session_id: string) {
  return useQuery<DrugPrescription[]>({
    queryKey: [`/prescription-drugs`, treatment_session_id],
    queryFn: ({ queryKey }) =>
      makeApiCall(`${queryKey[0]}`, '', '', {
        params: {
          nopaginate: true,
          treatment_session_id,
        },
      }),
    enabled: !!treatment_session_id,
  })
}

export function useMedication(id: string) {
  return useQuery<SingleMedication>([`medications/${id}`], {
    queryFn: ({ queryKey }: { queryKey: QueryKey }) => makeApiCall(`${queryKey[0]}`),
  })
}

export function useRefillHistory(medicationId: string, offset?: number, limit?: number | string) {
  return useQuery<{ items: Refill[]; item_count: number }>(
    [`/medications/${medicationId}/refill-requests`, offset, limit, medicationId],
    {
      queryFn: ({ queryKey }: { queryKey: QueryKey }) =>
        makeApiCall(`${queryKey[0]}`, '', '', {
          params: {
            offset,
            limit,
          },
        }),
    }
  )
}

export function useDiagnosis() {
  return useQuery<
    {
      id: string
      diagnosis_name: string
    }[]
  >([`/diagnoses/`], {
    queryFn: ({ queryKey }: { queryKey: QueryKey }) =>
      makeApiCall(`${queryKey[0]}`, '', '', {
        params: {
          nopaginate: true,
        },
        baseURL,
      }),
  })
}
