import AllRoutes from './routes'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { defaultQueryFn } from 'utils/api'
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      staleTime: 150,
    },
  },
})

function App() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <AllRoutes />
        </QueryClientProvider>
      </LocalizationProvider>
    </>
  )
}

export default App
