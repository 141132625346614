import axios, { AxiosRequestConfig } from 'axios'
import { TOKEN_KEY } from 'app-constants'
import { QueryKey } from '@tanstack/react-query'
import { Capacitor } from '@capacitor/core'

const baseURL = import.meta.env.VITE_API_BASEURL

async function makeApiCall<T = any>(
  url: string,
  method: AxiosRequestConfig['method'] = 'get',
  payload?: AxiosRequestConfig['data'],
  axiosRequestConfig?: Omit<AxiosRequestConfig, 'url' | 'method' | 'data'>
): Promise<T> {
  axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(TOKEN_KEY)}`
  axios.defaults.headers.common['x-eazymed-platform'] = Capacitor.getPlatform()
  try {
    if (!baseURL || typeof baseURL !== 'string') {
      throw new Error('VITE_API_BASEURL is not defined')
    }
    const { data } = await axios({
      url,
      method,
      data: payload,
      baseURL,
      ...axiosRequestConfig,
    })
    return data
  } catch (error: any) {
    // use the server error response if available
    if (error.response) {
      const serverMessage: string = error.response?.data?.message

      if (error.response.status === 401 && !window.location.href.includes('login')) {
        sessionStorage.removeItem('token')
        window.location.replace('/login')
      }

      throw new Error(serverMessage || 'Error performing operation')
    }
    // throw errors that happen in the browser as is
    throw new Error(error.message)
  }
}

export async function defaultQueryFn({ queryKey }: { queryKey: QueryKey }) {
  const data = await makeApiCall(`${baseURL}/${queryKey[0]}`, '', '', {
    headers: {
      'x-service-name': 'fastklinik-patient',
      'x-eazymed-platform': Capacitor.getPlatform(),
    },
    params: {
      nopaginate: true,
    },
  })

  return data
}

export default makeApiCall
