import { createContext, ReactNode, useReducer, useEffect } from 'react'
import axios from 'axios'
import { Navigate, useLocation } from 'react-router-dom'
import { ActionContextState, AppContextState } from 'types'
import {
  BALANCE_VISIBLE_KEY,
  LOGOUT_KEY,
  LOGOUT_TIMEOUT,
  REDIRECT_KEY,
  TOKEN_KEY,
} from 'app-constants'
import { Storage } from '@capacitor/storage'

const initialState: AppContextState = {
  tokenContent: null,
  isMenuExpanded: true,
  profile: null,
  balanceVisible: localStorage[BALANCE_VISIBLE_KEY]
    ? localStorage[BALANCE_VISIBLE_KEY]?.toLowerCase() === 'true'
    : true,
}

export const AppContext = createContext<AppContextState>(initialState)
export const ActionsContext = createContext<ActionContextState>({
  expandMenu: () => null,
  logOut: () => null,
  toggleBalance: () => null,
})

const reducer = (
  previousState: AppContextState,
  action: { type: string; payload?: any }
): AppContextState => {
  switch (action.type) {
    case 'login': {
      return { ...previousState, tokenContent: action.payload, profile: action.payload }
    }
    case 'expand_menu': {
      return { ...previousState, isMenuExpanded: !previousState.isMenuExpanded }
    }
    case 'toggle_balance': {
      return { ...previousState, balanceVisible: !previousState.balanceVisible }
    }
    default: {
      throw new Error('Invalid app reducer action type')
    }
  }
}

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  useIdleLogout(() => {
    window.sessionStorage.setItem(REDIRECT_KEY, window.location.pathname)
    logOut()
  })
  useAuthChanged()
  useLogOutFromOtherTabs(logOut)

  async function logOut() {
    axios.defaults.headers.common.Authorization = ''
    window.location.replace('/login')
    sessionStorage.removeItem('profile')
    sessionStorage.removeItem(TOKEN_KEY)
    sessionStorage.setItem(LOGOUT_KEY, Date.now().toString())
    Storage.clear()
  }

  const expandMenu = () => {
    dispatch({
      type: 'expand_menu',
    })
  }

  const toggleBalance = () => {
    localStorage.setItem(BALANCE_VISIBLE_KEY, JSON.stringify(!state.balanceVisible))
    dispatch({
      type: 'toggle_balance',
    })
  }

  useEffect(() => {
    async function initialize() {
      try {
        const token = sessionStorage.getItem(TOKEN_KEY)
        if (token) {
          dispatch({
            type: 'login',
            payload: {
              token,
            },
          })
        } else {
          throw 'You are not logged in'
        }
      } catch (error) {
        logOut()
      }
    }

    initialize()
  }, [])

  const [state, dispatch] = useReducer(reducer, initialState)

  if (!sessionStorage.getItem(TOKEN_KEY)) {
    return <Navigate to="/login" state={{ redirect: location }} />
  }

  return (
    <ActionsContext.Provider value={{ expandMenu, logOut, toggleBalance }}>
      <AppContext.Provider
        value={{
          tokenContent: state.tokenContent,
          isMenuExpanded: state.isMenuExpanded,
          profile: state.profile,
          balanceVisible: state.balanceVisible,
        }}
      >
        {children}
      </AppContext.Provider>
    </ActionsContext.Provider>
  )
}

function useLogOutFromOtherTabs(logOut: () => void) {
  useEffect(() => {
    const channel = new BroadcastChannel('loginChannel')

    const handleIframeMessage = (event: MessageEvent<{ currentLoggedInUser: string }>) => {
      const user = sessionStorage.profile && JSON.parse(sessionStorage.profile)
      const data = event.data.currentLoggedInUser && JSON.parse(event.data.currentLoggedInUser)
      if (user.email === data.email) {
        logOut()
      }
    }

    channel.addEventListener('message', handleIframeMessage)

    return () => {
      channel.removeEventListener('message', handleIframeMessage)
      channel.close()
    }
  }, [])
}

function useAuthChanged() {
  const location = useLocation()

  const syncLogout = (event: StorageEvent) => {
    if (event.key === LOGOUT_KEY) {
      return <Navigate to="/login" state={{ redirect: location }} />
    }
  }

  useEffect(() => {
    window.addEventListener('storage', syncLogout)

    return () => {
      window.removeEventListener('storage', syncLogout)
      window.sessionStorage.removeItem(LOGOUT_KEY)
    }
  }, [])
}

function useIdleLogout(logOut: () => void) {
  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>
    window.onload = resetTimer
    window.onmousemove = resetTimer //  mousemove actions
    window.onmousedown = resetTimer // touchscreen action
    window.ontouchstart = resetTimer // touchscreen swipes
    window.ontouchmove = resetTimer // required by some devices
    window.onclick = resetTimer // touchpad clicks
    window.onkeydown = resetTimer
    window.addEventListener('scroll', resetTimer, true)
    function endSession() {
      logOut()
    }
    function resetTimer() {
      clearTimeout(timerId)
      timerId = setTimeout(endSession, LOGOUT_TIMEOUT)
    }
    return () => {
      window.removeEventListener('load', resetTimer)
      window.removeEventListener('mousemove', resetTimer)
      window.removeEventListener('mousedown', resetTimer)
      window.removeEventListener('touchstart', resetTimer)
      window.removeEventListener('touchmove', resetTimer)
      window.removeEventListener('click', resetTimer)
      window.removeEventListener('keydown', resetTimer)
      window.removeEventListener('scroll', resetTimer)
    }
  }, [])
}
