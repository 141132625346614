import { AuthResponse } from 'types/auth'
import makeApiCall from '.'
const baseURL = import.meta.env.VITE_API_GLOBAL_URL

export async function login(payload: { email: string; password: string }) {
  return await makeApiCall<AuthResponse>('/auth/login', 'post', payload, {
    baseURL,
    headers: {
      'x-service-name': 'eazymed',
    },
  })
}

export async function register(payload: {
  email: string
  password: string
  first_name: string
  last_name: string
  phone: string
}) {
  return await makeApiCall<AuthResponse>('/auth/signup', 'post', payload, {
    baseURL,
    headers: {
      'x-service-name': 'eazymed',
    },
  })
}

export async function signOut() {
  return await makeApiCall('/auth/logout', 'post')
}

export async function requestPasswordReset(email: string) {
  return await makeApiCall(`/auth/forgot-password?email=${email}` , ''  , '' , {
    baseURL,
  })
}

export async function verifyPasswordReset(payload: { email: string; otp: string }) {
  return await makeApiCall(`/auth/forgot-password`, 'post', payload, {
    baseURL,
    headers: {
      'x-service-name': 'eazymed',
    },
  })
}

export async function resetPassword(email: string, password: string, token: string) {
  return await makeApiCall<AuthResponse>(
    `/auth/reset-password`,
    'post',
    { email, password },
    {
      baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export async function setPassword(password: string, token: string) {
  return await makeApiCall<AuthResponse>(
    `/auth/set-password `,
    'post',
    { password },
    {
      baseURL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}
