import React, { useState } from 'react'
import { Dialog, DialogProps, IconButton } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const ModalLayout = (props: {
  dialog: DialogProps
  close: () => void
  children: any
  title?: string
}) => {
  const { className, ...rest } = props.dialog

  return (
    <Dialog
      scroll="paper"
      maxWidth={'lg'}
      TransitionComponent={Transition}
      keepMounted
      closeAfterTransition
      {...rest}
    >
      <div className="hidden lg:flex mb-6 justify-end">
        <IconButton className="bg-white" onClick={props.close}>
          <CloseOutlined />
        </IconButton>
      </div>
      <div
        className={`px-4 pb-14 lg:min-w-[46rem] lg:max-w-[46rem] lg:px-24 fixed lg:relative bottom-0 left-0 bg-white rounded-tl-3xl rounded-tr-3xl lg:rounded-xl rounded-none w-full max-h-[80%] lg:max-h-full overflow-auto ${className}`}
      >
        <div className="flex justify-between mb-10 items-center sticky top-0 bg-white z-50 pt-4 lg:py-10">
          <p className="text-lg font-bold text-brand-400 font-sans">{props.title}</p>
          <IconButton onClick={props.close} className="lg:hidden">
            <CloseOutlined />
          </IconButton>
        </div>
        {props.children}
      </div>
    </Dialog>
  )
}

export const ModalState = (props: {
  children: (isVisible: boolean, open: () => void, close: () => void) => JSX.Element
}) => {
  const [isVisible, toggleVisibility] = useState(false)

  const open = () => toggleVisibility(true)
  const close = () => toggleVisibility(false)

  return props.children(isVisible, open, close)
}
