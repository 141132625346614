import { ProductSearchResult } from 'types/treatments'
import makeApiCall from '.'

export function searchDrug(keyword: string) {
  return makeApiCall<ProductSearchResult[]>(`/pharmacy/products/search-inventory`, 'get', '', {
    params: {
      keyword,
    },
  })
}

export function requestRefill(medicationId: string, is_delivery: boolean) {
  return makeApiCall(`/medications/${medicationId}/request-refill`, 'post', {
    is_delivery,
  })
}
