import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import makeApiCall from 'utils/api'
import {
  Order,
  orderStatus,
  OrderItems,
  OrderDetail,
  OrderRequest,
  OrderRequestDetail,
} from 'types/orders'
import { QueryKey } from 'react-query'

export function useOrders(
  orderStatus: orderStatus[],
  offset?: number,
  limit?: number,
  pharmacyId?: string,
  filters?: {
    from_date: string | undefined
    to_date: string | undefined
    date: string | undefined
  }
) {
  return useQuery<{ item_count: number; items: Order[] }>(
    ['orders', orderStatus, offset, limit, filters],
    {
      queryFn: ({ queryKey }: { queryKey: QueryKey }) =>
        makeApiCall(`${queryKey[0]}`, '', '', {
          params: {
            order_status: orderStatus.join(','),
            offset,
            limit,
            pharmacy_id: pharmacyId,
            ...filters,
          },
        }),
    }
  )
}
export function useOrderItems(orderId: string) {
  return useQuery<OrderItems[]>(['order-items', orderId], {
    queryFn: ({ queryKey }: { queryKey: QueryKey }) =>
      makeApiCall(`${queryKey[0]}`, '', '', {
        params: {
          order_id: orderId,
          nopaginate: true,
        },
      }),
  })
}
export function useOrder(id: string) {
  return useQuery<OrderDetail>([`/orders/${id}/details`], {
    queryFn: ({ queryKey }: { queryKey: QueryKey }) => makeApiCall(`${queryKey[0]}`),
  })
}

export function useOrderRequests(
  orderStatus: orderStatus[],
  offset?: number,
  limit?: number,
  pharmacyId?: string
) {
  return useQuery<{ item_count: number; items: OrderRequest[] }>(
    ['order-requests', orderStatus, offset, limit],
    {
      queryFn: ({ queryKey }: { queryKey: QueryKey }) =>
        makeApiCall(`${queryKey[0]}`, '', '', {
          params: {
            order_status: orderStatus.join(','),
            offset,
            limit,
            pharmacy_id: pharmacyId,
            request_status: 'pending',
          },
        }),
    }
  )
}

export function useInfiniteOrderRequests(
  orderStatus: orderStatus[],
  shouldFetch?: boolean,
  pharmacyId?: string
) {
  const fetchOrders = async ({ pageParam = 0 }) => {
    const res = await makeApiCall('/order-requests', '', '', {
      params: {
        order_status: orderStatus.join(','),
        offset: pageParam,
        limit: 5,
        pharmacy_id: pharmacyId,
      },
    })
    return res
  }

  return useInfiniteQuery<{ items: OrderRequest[]; item_count: number; offset: number }>({
    queryKey: ['order-requests', orderStatus, pharmacyId],
    queryFn: fetchOrders,
    getNextPageParam: (lastPage) => lastPage.offset + 5,
    enabled: shouldFetch,
  })
}

export function useOrderRequest(id: string) {
  return useQuery<OrderRequestDetail>([`/order-requests/${id}/details`], {
    queryFn: ({ queryKey }: { queryKey: QueryKey }) => makeApiCall(`${queryKey[0]}`),
  })
}
