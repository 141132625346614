import { ModalLayout } from 'components/modal'
import { Formik } from 'formik'
import {
  Button,
  Alert,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  CircularProgress,
} from '@mui/material'
import yup from 'utils/yup'
import { useEffect, useState } from 'react'
import { UserProfile } from 'types/auth'
import { usePaymentRequest } from 'mutations/payment'
import { ReactComponent as Wallet } from 'assets/icons/wallet.svg'
import { ReactComponent as Card } from 'assets/icons/card-gateway.svg'
import { useSnackbar } from 'notistack'
import { usePaystackPayment } from 'react-paystack'
import { Paymentgateway } from 'types/shipping'

const validationSchema = yup.object().shape({
  payment_option: yup.string().required('select payment method'),
})

export default function MakePaymentModal(props: {
  close: () => void
  isVisible: boolean
  requestId: string
  profile: UserProfile
  amount: number
}) {
  const { requestId } = props
  const fundDeposit = usePaymentRequest()
  const [error, setError] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [config, setConfig] = useState({
    reference: new Date().getTime().toString(),
    email: props.profile.email,
    amount: 0, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: '',
  })
  const [status, setStatus] = useState<null | 'new-payment' | 'payment-done'>(null)
  const initializePayment = usePaystackPayment(config)

  useEffect(() => {
    status === 'new-payment' &&
      initializePayment(
        () => {
          enqueueSnackbar('Payment made successfully', { variant: 'success' })
          props.close()
          setStatus(null)
        },
        () => {
          setStatus(null)
        }
      )
  }, [status])

  return (
    <ModalLayout
      dialog={{
        open: props.isVisible,
        onClose: () => {
          props.close()
        },
        className: 'lg:w-[66rem]',
      }}
      title="Payment Option"
      close={() => {
        props.close()
      }}
    >
      <div className="space-y-8">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            payment_option: '',
          }}
          onSubmit={async (values) => {
            setError('')
            fundDeposit.mutate(
              {
                id: requestId,
                payload: {
                  payment_option: values.payment_option as Paymentgateway,
                },
              },
              {
                onSuccess: (res) => {
                  if (values.payment_option === 'wallet') {
                    enqueueSnackbar('Payment made successfully', { variant: 'success' })
                    props.close()
                  } else {
                    setConfig({
                      ...config,
                      reference: res.payment.reference,
                      amount: res.payment.amount,
                      publicKey: res.payment.public_key,
                    })
                    setStatus('new-payment')
                  }
                },
                onError: (e: any) => setError(e.message),
              }
            )
          }}
        >
          {({ handleSubmit, handleChange, touched, errors }) => (
            <form onSubmit={handleSubmit}>
              <RadioGroup className="space-y-6" onChange={handleChange} name="payment_option">
                <div className="flex items-center">
                  <FormControlLabel
                    value="wallet"
                    control={<Radio className="text-brand-400" />}
                    label=""
                  />
                  <div className="flex space-x-3 items-center">
                    <Wallet className="text-3xl" />
                    <p className="font-bold text-lg text-neutral-500">Wallet</p>
                  </div>
                </div>

                <div className="flex items-center">
                  <FormControlLabel
                    value="gateway"
                    control={<Radio className="text-brand-400" />}
                    label=""
                  />
                  <div className="flex space-x-3 items-center">
                    <Card className="text-3xl" />
                    <p className="font-bold text-lg text-neutral-500">Card</p>
                  </div>
                </div>
              </RadioGroup>
              <FormHelperText>{touched && errors.payment_option}</FormHelperText>

              <Alert
                severity={'error'}
                className={`${!error ? 'opacity-0' : ''} my-4`}
                aria-hidden={!error}
                data-test-id="login-server-error"
              >
                {error}
              </Alert>
              <Button fullWidth type="submit" disabled={fundDeposit.isLoading}>
                {fundDeposit.isLoading ? <CircularProgress /> : 'Proceed to payment'}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </ModalLayout>
  )
}
