export const TOKEN_KEY = 'FAST_TOKEN'
export const TIMEOUT_KEY = 'FAST_TIMEOUT'
export const LOGOUT_KEY = 'FAST_WEB_APP_LOGOUT'
export const LOGOUT_TIMEOUT =  10 * 60 * 1000 // 10 minutes
export const SPECIALIZATION = ['Paediatrics', '  Psychiatric', 'Dental']
export const TITLE = ['Mr', 'Mrs', 'Ms']
export const GENDER = ['male', 'female']
export const CATEGORY = ['adult', 'paediatric']
export const MARITAL_STATUS = ['single', 'married', 'divorced', 'widowed']
export const BLOOD_GROUP = ['A', 'B', 'AB', 'O']
export const GENOTYPE = ['AA', 'AS', 'AC', 'SS', 'SC', 'CC']
export const RHESUS_FACTOR = ['positive', 'negative']
export const EMPLOYMENT_TYPES = ['fulltime', 'partime']
export const EMPLOYMENT_STATUS = ['employed', 'unemployed']
export const WARD_TYPES = <const>['adult male', 'adult female', 'children male', 'children female']
export const WARD_CATEGORIES = <const>['regular', 'vip', 'exclusive']
export const CHARGE_FREQUENCY = <const>['daily', 'weekly', 'monthly']
export const RELIGION = ['christian', 'islam', 'traditional', 'others']
export const SCHEDULE_TYPES = <const>['surgery', 'injections', 'follow-up', 'therapy', 'other']
export type WardType = typeof WARD_TYPES[number]
export type WardCategoryType = typeof WARD_CATEGORIES[number]
export const REDIRECT_KEY = 'FAST_REDIRECT'
export const ONBOARD_KEY = 'FAST_ONBOARDED'
export const BALANCE_VISIBLE_KEY = 'FAST_BALANCE_VISIBLE'
export const USSD_SUPPORTTED_BANKS = [
  {
    icon: 'https://nigerianbanks.xyz/logo/guaranty-trust-bank.png',
    name: 'GTB Bank',
    ussd: '737',
  },
  {
    icon: 'https://nigerianbanks.xyz/logo/sterling-bank.png',
    name: 'Sterling Bank',
    ussd: '822',
  },
  {
    icon: 'https://nigerianbanks.xyz/logo/zenith-bank.png',
    name: 'Zenith Bank',
    ussd: '966',
  },
  {
    icon: 'https://nigerianbanks.xyz/logo/united-bank-for-africa.png',
    name: 'United Bank of Africa',
    ussd: '919',
  },
]

export const PROVIDERS = [
  {
    name: 'Clinics',
    to: 'clinics',
  },
  {
    name: 'Pharmacies',
    to: 'pharmacies',
  },
  {
    name: 'Find Providers',
    to: 'other-providers',
  },
] as const
export const ITEMS_PER_PAGE = 5
