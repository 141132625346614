import { Formik } from 'formik'
import {
  Button,
  TextField,
  IconButton,
  Alert,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import {
  CheckCircle,
  RadioButtonUncheckedOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import { useState } from 'react'
import yup from 'utils/yup'
import { setPassword } from 'utils/api/auth'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router-dom'

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Enter your password')
    .containsSpecialCharacter("Your password doesn't meet the requirements")
    .containsUppercase("Your password doesn't meet the requirements")
    .min(8, "Your password doesn't meet the requirements"),
  confirm: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match'),
})

function SetPassword() {
  const location = useLocation()
  const { token } = location.state || {}
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [isPasswordVisible, setPasswordVisibility] = useState(false)
  const [isConfirmPasswordVisible, setPasswordConfirmVisibility] = useState(false)
  const [error, setError] = useState('')

  return (
    <div className="h-full">
      <div className="bg-white shadow p-10 w-[30rem] rounded-3xl">
        <div className="mb-8">
          <h1 className="text-center font-medium text-xl">Set New Password</h1>
        </div>
        <Formik
          initialValues={{ confirm: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              setError('')
              await setPassword(values.password, token)

              enqueueSnackbar('password has been set successfully', { variant: 'success' })
              navigate('/login')
            } catch (error: any) {
              setError(error.message)
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <p className="mb-2 font-medium">Password</p>
              <TextField
                id="password"
                type={isPasswordVisible ? 'text' : 'password'}
                className="border-red mb-4"
                value={values.password}
                onChange={handleChange}
                error={touched.password && !!errors.password}
                placeholder="Type your password"
                helperText={touched.password ? errors.password : ''}
                fullWidth
                inputProps={{
                  className: 'rounded-[11px]',
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setPasswordVisibility(!isPasswordVisible)}
                      className="text-neutral-600"
                    >
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      <span className="sr-only">
                        {isPasswordVisible ? 'Hide Password' : 'Show Password'}
                      </span>
                    </IconButton>
                  ),
                }}
              />
              <p className="mb-2 font-medium">Password Confirmation</p>
              <TextField
                id="confirm"
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                className="border-red"
                value={values.confirm}
                onChange={handleChange}
                error={touched.confirm && !!errors.confirm}
                placeholder="Type your confirm"
                helperText={touched.confirm ? errors.confirm : ''}
                fullWidth
                inputProps={{
                  className: 'rounded-[11px]',
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setPasswordConfirmVisibility(!isConfirmPasswordVisible)}
                      className="text-neutral-600"
                    >
                      {!isConfirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                      <span className="sr-only">
                        {isConfirmPasswordVisible ? 'Hide Password' : 'Show Password'}
                      </span>
                    </IconButton>
                  ),
                }}
              />
              <Alert
                severity="error"
                className={`mt-4 ${!error ? 'opacity-0' : ''}`}
                aria-hidden={!error}
                data-test-id="login-server-error"
              >
                {error}
              </Alert>
              <Button
                type="submit"
                disabled={isSubmitting}
                className="mt-4 p-3 rounded-md"
                fullWidth
              >
                {isSubmitting ? (
                  <>
                    <CircularProgress color="inherit" className="text-white" size={22} />
                    <span className="sr-only">Submitting</span>
                  </>
                ) : (
                  'SET PASSWORD'
                )}
              </Button>
              <FormGroup className="mt-4 pointer-events-none">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.password.length >= 8}
                      icon={<RadioButtonUncheckedOutlined className="text-primary-dark" />}
                      checkedIcon={<CheckCircle className="text-primary-dark" />}
                      tabIndex={-1}
                      inputProps={{
                        'aria-hidden': true,
                      }}
                      id="password-validation-eight-characters"
                    />
                  }
                  label="Minimum of 8 characters"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={/[A-Z]+/.test(values.password)}
                      icon={<RadioButtonUncheckedOutlined className="text-primary-dark" />}
                      checkedIcon={<CheckCircle className="text-primary-dark" />}
                      tabIndex={-1}
                      inputProps={{
                        'aria-hidden': true,
                      }}
                      id="password-validation-uppercase-character"
                    />
                  }
                  label="One UPPERCASE character"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={/[!@#$%^&*?]+/.test(values.password)}
                      icon={<RadioButtonUncheckedOutlined className="text-primary-dark" />}
                      checkedIcon={<CheckCircle className="text-primary-dark" />}
                      tabIndex={-1}
                      inputProps={{
                        'aria-hidden': true,
                      }}
                      id="password-validation-special-character"
                    />
                  }
                  label="One unique character (e.g: !@#$%^&*?)"
                />
              </FormGroup>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default SetPassword
