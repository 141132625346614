import { PayInvoicePayload, PaymentRequestPayload } from 'types/payment'
import makeApiCall from '.'

export function fundWallet(payload: { amount: number }) {
  return makeApiCall(`/transactions/`, 'post', payload)
}

export function payInvoice(invoiceId: string, payload: PayInvoicePayload) {
  return makeApiCall(`/invoices/${invoiceId}/make-payment`, 'post', payload)
}
export function paymentRequest(requestId: string, payload: PaymentRequestPayload) {
  return makeApiCall(`/order-requests/${requestId}/pay`, 'post', payload)
}
