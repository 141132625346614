import {DepositPayload } from 'types/bill'
import makeApiCall from '.'

export async function makeDeposit(
  patient_id: string,
  payload: {
    amount: string
    payment_gateway: 'cash' | 'bank-transfer' | 'ussd' | string
    paid_by: string
    ussd_code?: string
  }
) {
  return await makeApiCall(`/patients/${patient_id}/make-deposit`, 'post', payload, {
    headers: {
      'x-account-id': JSON.parse(sessionStorage.getItem('account') || '').account_id,
    },
  })
}

export function fundDeposit(payload: DepositPayload) {
  return makeApiCall(`/wallets/transfer`, 'post', payload)
}
