import { ModalLayout } from 'components/modal'
import { RadioButtonUncheckedOutlined } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useState } from 'react'
import { ReactComponent as PickupBag } from 'assets/icons/pickup-bag.svg'
import { ReactComponent as Delivery } from 'assets/icons/door-delivery-icon.svg'
import { useRequestRefill } from 'mutations/medication'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { SingleMedication } from 'types/treatments'

const data = [
  {
    text: 'Pickup',
    value: 'pickup',
    icon: <PickupBag className="text-neutral-500 text-4xl" />,
  },
  {
    text: 'Door Delivery',
    value: 'door',
    icon: <Delivery className="text-neutral-500 text-4xl" />,
  },
]

export default function SelectShippingModal(props: {
  close: () => void
  isVisible: boolean
  medication: SingleMedication
}) {
  const [option, setOption] = useState('')
  const requestRefill = useRequestRefill()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  function submitRequest() {
    if (props.medication) {
      requestRefill.mutate(
        { id: props.medication.id, is_delivery: option === 'door' },
        {
          onSuccess: () => {
            enqueueSnackbar('operation successful', { variant: 'success' })
            navigate(-1)
          },
          onError: (e: any) => enqueueSnackbar(e.message, { variant: 'error' }),
        }
      )
    }
  }

  return (
    <>
      <ModalLayout
        dialog={{
          open: props.isVisible,
          onClose: props.close,
          className: 'pb-20',
        }}
        title="Shipping Option"
        close={props.close}
      >
        <div className="space-y-8">
          <p className="font-medium text-sm text-neutral-500">
            Choose how you want your medication to be delivered
          </p>
          <div className="space-y-4">
            <FormControl className="w-full">
              <RadioGroup
                className="space-y-4"
                onChange={(e) => {
                  setOption(e.target.value)
                }}
              >
                {data?.map((item, index) => (
                  <div className="space-y-4 p-4 flex flex-col" key={index}>
                    <FormControlLabel
                      value={item.value}
                      control={
                        <Radio icon={<RadioButtonUncheckedOutlined />} className="text-brand-400" />
                      }
                      label={
                        <div className="flex items-center text-lg font-bold text-neutral-500 space-x-4">
                          {item.icon}
                          <p>{item.text}</p>
                        </div>
                      }
                      classes={{
                        label: 'font-medium font-raleway',
                      }}
                    />
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
            <Button onClick={submitRequest} disabled={requestRefill.isLoading || !option} fullWidth>
              {requestRefill.isLoading ? (
                <CircularProgress className="text-white" />
              ) : (
                'Request Refill'
              )}
            </Button>
          </div>
        </div>
      </ModalLayout>
    </>
  )
}
